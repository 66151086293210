function declOfNum(number, titles) {  
  cases = [2, 0, 1, 1, 1, 2];  
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

function isMobile() {
  return $('@mobile-on').is(':visible');
}

var Map = {
  decodeLocation(locationString) {
    var location = locationString.split(',');
    return location
  },
  setMarker(location) {
    var t = this
    var marker = new ymaps.Placemark( t.decodeLocation( location ), {
    }, {
      iconLayout: 'default#image',
      iconImageHref: '/images/icons/map-point.png',
      iconImageSize: [ 91, 91 ],
      iconImageOffset: [ -45, -45 ],
    } )
    t.map.geoObjects.add( marker )
  },
  setMarkers() {
    var t = this;
    $('[data-location]').each(function(){
      t.setMarker($(this).attr('data-location'));
    });
  },
  setCenter(location) {
    this.map.setCenter( this.decodeLocation( location ) )
  },
  init() {
    var t = this;
    if( t.inited ) return
    t.map = myMap = new ymaps.Map( 'map', {
      center: [ 54.752190, 55.981780 ],
      zoom: 12,
    } )
    t.setMarkers()
    t.inited = true
  },
}

var Blocks = {
  $close: $('@block-close'),
  timeouts: [],
  dash: $('@benefits-dash')[0],
  opened: 0,
  $benefitsSlider: $('@benefits-slider'),
  open(block) {
    var t = this;
    var openDelay = 0;
    var $block = $('[data-block="' + block + '"]');
    if(!$block.length) return;
    if(block != 'feedback') $('[data-open]').addClass('isFaded');
    if(block == 'contacts' && !isMobile()) Map.init();
    if(!isMobile() && block == 'benefits') $('@body').addClass('isBlue');
    $block.show();
    clearTimeout(t.timeouts[block]);
    t.timeouts[block] = setTimeout(function(){
      $block.addClass('isActive');
      if(isMobile() && block == 'benefits') {
        t.$benefitsSlider.slick({
          dots: true,
          arrows: false
        });
      }
      if(!isMobile() && block == 'catalog' && !t.productsScrollSet) {
        t.productsScrollSet = true;
        $('@catalog-slider').mCustomScrollbar({
          axis: 'x',
          setLeft: 0
        });
      }
    }, 10);
    if(block == 'benefits') {
      TweenLite.to(t.dash, .5, {top: '6.45484vw', left: '4.82581vw', ease: Power2.easeInOut, delay: .6});
    } else {
      TweenLite.to(t.dash, .5, {opacity: 0, ease: Power2.easeInOut});
    }
    t.opened++;
  },
  close(block) {
    var t = this;
    var $block = $('[data-block="' + block + '"]');
    $block.removeClass('isActive');
    $('@body').removeClass('isBlue');
    clearTimeout(t.timeouts[block]);
    t.timeouts[block] = setTimeout(function(){
      $block.hide();
      if(isMobile() && block == 'benefits') {
        t.$benefitsSlider.slick('unslick');
      }
    }, isMobile() ? 600 : 1600);
    if(block != 'feedback') $('[data-open]').removeClass('isFaded');
    t.opened--;
    if(t.opened == 0) {
      TweenLite.to(t.dash, .5, {top: '6.85484vw', left: '53.22581vw', opacity: 1, ease: Power2.easeInOut, delay: (block == 'benefits' ? .4 : .6)});
    }
  },
  init() {
    var t = this;
    $('[data-open]').on('click', function(e){
      e.preventDefault();
      t.open($(this).attr('data-open'));
    });
    t.$close.on('click', function(e){
      e.preventDefault();
      t.close($(this).attr('data-close'));
    });
    $('@feedback-open').on('click', function(e){
      e.preventDefault();
      if($('[data-block="benefits"]').hasClass('isActive')) {
        t.close('benefits');
        setTimeout(function(){
          t.open('feedback');
        }, 1200);
      } else {
        t.open('feedback');
      }
    });
    $('@benefits-next').on('click', function(e){
      e.preventDefault();
      t.$benefitsSlider.slick('slickNext');
    });
  }
}

var SvgAnimation = {
  $svg: $('@animated-svg'),
  icons: [],
  activeIndex: -1,
  show(eq) {
    var t = this;
    var svg = t.icons[eq];
    if(!svg) return;
    svg.$elem.addClass('isActive');
    svg.vivus.reset();
    svg.vivus.play();
    t.activeIndex = eq;
  },
  hide(eq, callback) {
    var t = this;
    var svg = t.icons[eq];
    svg.vivus.play(-1, function(){
      svg.$elem.removeClass('isActive');
      callback();
    });
  },
  hideHard(eq) {
    var t = this;
    var svg = t.icons[eq];
    svg.$elem.removeClass('isActive');
  },
  change(eq) {
    var t = this;
    t.hideHard(t.activeIndex);
    t.show(eq);
  },
  play() {
    var t = this;
    var next = t.activeIndex + 1;
    if(next == t.icons.length) {
      next = 0;
    }
    function forward() {
      setTimeout(function(){
        t.play();
      }, 5000);
    }
    if(t.activeIndex == -1) {
      t.show(next);
      forward();
    } else {
      t.hide(t.activeIndex, function(){
        t.show(next);
        forward();
      });
    }
  },
  init() {
    var t = this;
    t.$svg.each(function(i){
      var $this = $(this);
      t.icons[i] = {
        $elem: $this,
        vivus: new Vivus($this.attr('id'), {
          duration: 200,
          animTimingFunction: Vivus.EASE,
          type: 'oneByOne'
        })
      }
      t.icons[i].vivus.stop();
    });
    // t.play();
  }
}

var Sliders = {
  init() {
    var $mainSlider = $("@main-slider");
    $mainSlider.slick({
      dots: true,
      arrows: false,
      fade: !isMobile(),
      autoplay: true,
      autoplaySpeed: 7000,
    });
    SvgAnimation.show(0);
    $mainSlider.on('beforeChange', function(a, b, c, nextSlide){
      SvgAnimation.change(nextSlide);
    });
    $('@main-slider-next').on('click', function(e){
      e.preventDefault();
      $mainSlider.slick('slickNext');
    });
    if(isMobile()) {
      var $catalogSlider = $('@mobile-catalog @catalog-slider-mobile');
      $catalogSlider.slick({
        dots: true,
        arrows: false
      });
      function setMobileOrder(eq) {
        if(eq === undefined) eq = $catalogSlider.slick('slickCurrentSlide');
        console.log(eq);
        var $item = $('@catalog-item').eq(eq);
        var order = $item.find('[data-order]').attr('data-order');
        $('@mobile-order').attr('data-order', order);
      }
      setMobileOrder();
      $catalogSlider.on('beforeChange', function(a, b, c, nextSlide){
        setMobileOrder(nextSlide);
      });
      $('@catalog-next').on('click', function(e){
        e.preventDefault();
        $catalogSlider.slick('slickNext');
      });
    }
  }
}

var Textarea = {
  $textarea: $('@textarea'),
  count() {
    var t = this;
    var left = t.max - t.$textarea.val().length;
    var str = left + ' ' + declOfNum(left, ['символ', 'символа', 'символов']);
    $('@textarea-left').html(str);
  },
  init() {
    var t = this;
    t.max = parseInt(t.$textarea.attr('maxlength'));
    t.count();
    t.$textarea.on('input change', function(){
      t.count();
    });
  }
}

var Order = {
  init() {
    $('[data-order]').on('click', function(e){
      e.preventDefault();
      var order = $(this).attr('data-order');
      $('@textarea').val(`Здравствуйте, меня интересует «${order}».`).trigger('change');
      Blocks.open('feedback');
    });
  }
}

var Masks = {
  init() {
    $('[data-mask-type="phone"]').mask('000 (000) 000-00-00', {reverse: true});
  }
}

var Navigation = {
  navigate(location) {
    // var base = '://maps.google.com/maps/dir/Current+Location/' + location;
    // window.open('http' + base);
  },
  init() {
    var t = this;
    $('@navigate').on('click', function (e) {
      e.preventDefault();
      t.navigate($(this).attr('data-navigate-location'));
    });
  }
};

var FeedbackForm = {
  $form: $('@feedback-form'),
  $submit: $('@feedback-form [type="submit"]'),
  check() {
    var t = this;
    var name = t.$form.find('[name="name"]').val() != '';
    var phone = t.$form.find('[name="phone"]').val() != '';
    var email = t.$form.find('[name="email"]').val() != '';
    var message = t.$form.find('[name="message"]').val() != '';
    if(name && (phone || email) && message) {
      t.$submit.removeAttr('disabled');
    } else {
      t.$submit.attr('disabled', 'disabled');
    }
  },
  animation() {
    var t = this;
    var $fields = t.$form.find('input, textarea');
    var titleBefore = $('@feedback-title-before')[0];
    var titleAfter = $('@feedback-title-after')[0];
    var formBefore = $('@feedback-form')[0];
    var formAfter = $('@feedback-form-after')[0];
    $(titleAfter).add(formAfter).show();
    TweenLite.to(titleBefore, .4, {opacity: 0, ease: Power2.easeInOut});
    TweenLite.to(formBefore, .4, {opacity: 0, ease: Power2.easeInOut, delay: .1});
    TweenLite.to(titleAfter, .4, {opacity: 1, ease: Power2.easeInOut, delay: .4});
    TweenLite.to(formAfter, .4, {opacity: 1, ease: Power2.easeInOut, delay: .5});
    setTimeout(function(){
      Blocks.close('feedback');
      setTimeout(function(){
        $fields.val('');
        $fields.trigger('change');
        TweenLite.to(titleBefore, 0, {opacity: 1, ease: Power2.easeInOut});
        TweenLite.to(formBefore, 0, {opacity: 1, ease: Power2.easeInOut});
        TweenLite.to(titleAfter, 0, {opacity: 0, ease: Power2.easeInOut});
        TweenLite.to(formAfter, 0, {opacity: 0, ease: Power2.easeInOut});
        $(titleAfter).add(formAfter).hide();
      }, 600);
    }, 5000);
  },
  submit(data, action) {
    var t = this;
    t.$submit.attr('disabled', 'disabled');
    $.ajax({
      url: action,
      data: data,
      type: 'post'
    }).done(function(res){
      t.animation();
      t.$submit.removeAttr('disabled');
    }).fail(function(res){
      console.log(res);
    });
  },
  init() {
    var t = this;
    t.check();
    t.$form.find('input, textarea').on('change input', function(){
      t.check();
    });
    t.$form.on('submit', (e)=> {
      e.preventDefault();
      t.submit(t.$form.serialize(), t.$form.attr('action'));
    });
  }
}

var Contacts = {
  activeCity: false,
  show(city) {
    var block = $('[data-contacts="' + city + '"]');
    var link = $('[data-contacts-open="' + city + '"]');
    block.show()
      .siblings().hide();
    link.addClass('isActive')
      .siblings().removeClass('isActive');
    if(Map.map) {
      Map.setCenter(link.attr('data-location'));
    }
    this.activeCity = city
    $('@navigate').attr( 'href', 'https://maps.google.com/maps/dir/Current+Location/' +  link.attr('data-location') )
  },
  init() {
    var t = this;
    t.show('moscow');
    $('[data-contacts-open]').on('click', function(){
      t.show($(this).attr('data-contacts-open'));
    });
  }
}

var Logo = {
  init() {
    $('@logo').on('click', function(){
      $('[data-block].isActive').each(function(){
        Blocks.close($(this).attr('data-block'));
      });
    });
  }
}

function StartAnimation() {
  var content = $('@an-content')[0];
  var footer = $('@an-footer')[0];
  var header = $('@an-header')[0];
  var benefits = $('[data-open="benefits"]')[0];
  var dash = $('@benefits-dash')[0];
  if(!isMobile()) {
    TweenLite.to(content, 1, {opacity: 1, rotationX: 0, ease: Power2.easeInOut});
    TweenLite.to(header, .5, {opacity: 1, ease: Power2.easeInOut, delay: 1});
    TweenLite.to(footer, .5, {opacity: 1, ease: Power2.easeInOut, delay: 1});
    TweenLite.to(benefits, .5, {css: {transform: 'translateX(0)'}, ease: Power2.easeInOut, delay: 1});
    TweenLite.to(dash, .5, {opacity: 1, ease: Power2.easeInOut, delay: 1});
    setTimeout(function(){
      $('@main-slider-next').removeClass('isFaded');
    }, 250);
    setTimeout(function(){
      $('@main-screen').removeClass('isPerspective');
    });
  }
}

$(function(){
  Blocks.init();
  SvgAnimation.init();
  Sliders.init();
  Navigation.init();
  Textarea.init();
  Order.init();
  Masks.init();
  FeedbackForm.init();
  Contacts.init();
  Logo.init();
  StartAnimation();
});